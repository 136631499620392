import React, { Component, Suspense, useEffect } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { CSpinner } from '@coreui/react'
import './scss/style.scss'
import { useSelector } from 'react-redux'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Policy = React.lazy(() => import('./views/pages/Policy/Policy'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  const loader = useSelector((state) => state.loader)
  const AuthState = useSelector((state) => state.AuthState)
  useEffect(() => {
    if(!AuthState.isUserLogin){
      <Navigate to="/login" replace={true} />
    }
  },[])

  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        {loader &&
          <div className='loader-style'>
            <CSpinner className='spinner-style' />
          </div>
        }
        <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/privacy" name="Policy Page" element={<Policy page={'privacy'} />} />
            <Route exact path="/tnc" name="Policy Page" element={<Policy page={'tnc'} />} />
            <Route exact path="/refund" name="Policy Page" element={<Policy page={'refund'} />} />
          {/* <Route exact path="/register" name="Register Page" element={<Register />} /> */}
          {/* <Route exact path="/404" name="Page 404" element={<Page404 />} /> */}
          {/* <Route exact path="/500" name="Page 500" element={<Page500 />} /> */}
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
